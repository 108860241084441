














































































































































































































import useSelectItems from "@/use/selectItems";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import validator from "validator";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        isActive: false,
        name: "",
        city: "",
        street: "",
        postalCode: "",
        nip: "",
        phoneNumber: "",
        note: "",
        representativeFirstName: "",
        representativeLastName: "",
        representativePhoneNumber: "",
        representativeEmail: "",
        invoiceNumber: "",
        dateOfInvoice: "",
        invoicePaymentDate: "",
        package: "",
      }),
    },
  },

  setup(props, { root }) {
    const { languageItems } = useSelectItems({ root });
    const state = reactive({
      dateOfInvoiceClose: false,
      invoicePaymentDateClose: false,
      nowDate: "",
      minDate: computed(() => props.value.startDate),
      endDate: computed(() => props.value.endDate),
      amount: false,
      loading: false,
      success: false,
      error: false,
      loaded: false,
      dialog: false,
    });

    const model = reactive({
      data: {
        isActive: false,
        name: null,
        city: null,
        street: null,
        postalCode: null,
        nip: null,
        note: null,
        phoneNumber: null,
        representativeFirstName: null,
        representativeLastName: null,
        representativePhoneNumber: null,
        representativeEmail: null,
        invoiceNumber: null,
        dateOfInvoice: null,
        invoicePaymentDate: null,
        package: "none",
        languageVersion: "polish",
      },
    });

    const rules = {
      postalCode: [
        (v: string) =>
          !v ||
          validator.isPostalCode(v, "PL") ||
          root.$tc("layout.misc.validZipCode"),
      ],
      phoneNumber: [
        (v: string) =>
          !v ||
          validator.isMobilePhone(v, ["pl-PL"]) ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
      phoneNumberBasic: [
        (v: string) =>
          !v ||
          (v.length >= 9 && validator.isNumeric(v, { no_symbols: true })) ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
      nipNumber: [
        (v: string) =>
          !v ||
          (v.length >= 8 && validator.isNumeric(v, { no_symbols: true })) ||
          root.$tc("layout.misc.validNipNumber"),
      ],
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`company/${root.$route.params.cid}`)
        .then(({ data: { company } }) => {
          model.data.isActive = company.isActive || false;
          model.data.name = company.name;
          model.data.city = company.city || null;
          model.data.street = company.street || null;
          model.data.postalCode = company.postalCode || null;
          model.data.nip = company.nip || null;
          model.data.note = company.note || null;
          model.data.phoneNumber = company.phoneNumber || null;
          model.data.representativeFirstName =
            company.representativeFirstName || null;
          model.data.representativeLastName =
            company.representativeLastName || null;
          model.data.representativePhoneNumber =
            company.representativePhoneNumber || null;
          model.data.representativeEmail = company.representativeEmail || null;
          model.data.invoiceNumber = company.invoiceNumber || null;
          model.data.dateOfInvoice = company.dateOfInvoice || null;
          model.data.invoicePaymentDate = company.invoicePaymentDate || null;
          model.data.package = company.package || "none";
          model.data.languageVersion = company.languageVersion || "none";
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        isActive: model.data.isActive || false,
        name: model.data.name,
        city: model.data.city || null,
        street: model.data.street || null,
        postalCode: model.data.postalCode || null,
        nip: model.data.nip || null,
        phoneNumber: model.data.phoneNumber || null,
        note: model.data.note || null,
        representativeFirstName: model.data.representativeFirstName || null,
        representativeLastName: model.data.representativeLastName || null,
        representativePhoneNumber: model.data.representativePhoneNumber || null,
        representativeEmail: model.data.representativeEmail || null,
        invoiceNumber: model.data.invoiceNumber || null,
        dateOfInvoice: model.data.dateOfInvoice || null,
        invoicePaymentDate: model.data.invoicePaymentDate || null,
        package: model.data.package || "none",
        languageVersion: model.data.languageVersion || "polish",
      };

      state.loading = true;

      axiosInstance
        .put(`company/${root.$route.params.cid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.dialog = true;
          state.error = false;
        })
        .catch((error) => {
          state.error = error.response.status;
          state.dialog = true;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.companyExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    return { state, model, rules, onSubmit, getErrorMessage, languageItems };
  },
});
